import './DeleteSchedule.less'

import React from 'react'

import { useScopedIntl } from '../../../../../../../hooks'
import { deleteSchedule } from '../../../../../../../requests'
import { DatacMessage, DatacModal } from '../../../../../../common'

interface Props {
  onClose: (reload: boolean) => void
  scheduleId: number
}

export const DeleteSchedule: React.FC<Props> = ({ onClose, scheduleId }) => {
  const intlDelete = useScopedIntl('recruitment.study.schedules.delete')
  const intl = useScopedIntl('')

  const onSubmit = () => {
    deleteSchedule(
      { scheduleId },
      {
        onSuccess: () => {
          onClose(true)
          DatacMessage.success(intl('common.success'), intlDelete('success'))
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }
  return (
    <DatacModal
      title={intlDelete('title')}
      isOpened={!!scheduleId}
      onClose={() => onClose(false)}
      onSubmit={onSubmit}
      submitLabel={intl('common.delete')}
      className="delete-schedule"
    >
      {intlDelete('description')}
    </DatacModal>
  )
}
